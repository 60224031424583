<template>
  <div>
    <Header />
    <pageTitle txt="店舗別売上月報" :backBtn="backBtn" />
    <div id="meta">
      <div class="meta-date">{{ date | dateFormatYM }}</div>
      <div class="meta-month">
        <input
          type="tel"
          class="meta-year__input"
          v-model="year"
          max-length="4"
        />
        年
        <input
          type="tel"
          class="meta-month__input"
          v-model="month"
          max-length="2"
        />
        月
        <div>
          <btnXS btnTxt="表示" color="neutral" @click="setYM" />
        </div>
      </div>
      <div class="meta-btn">
        <div class="meta-btn__prev" @click="beforeDate">前の月</div>
        <div>
          <btnS btnTxt="今月" color="neutral" @click="setToday" />
        </div>
        <div class="meta-btn__next" @click="nextDate">次の月</div>
      </div>
      <div v-on:click="(print = !print), setPrint()" class="print-btn">
        <btnS class="print" btnTxt="印刷" color="primary" />
      </div>
    </div>
    <div id="wrapper">
      <ul class="list">
        <li class="list-head">
          <div class="list-head__item col-name">店舗名</div>
          <div class="list-head__item col-quantity">総売上数量</div>
          <div class="list-head__item col-sales">総売上高</div>
        </li>
        <li class="list-head">
          <div class="list-head__item col-name">店舗名</div>
          <div class="list-head__item col-quantity">総売上数量</div>
          <div class="list-head__item col-sales">総売上高</div>
        </li>
        <li class="list-item" v-for="shop in list" :key="shop.store_id">
          <div class="list-item__data col-name">{{ shop.name }}</div>
          <div class="list-item__data col-quantity">
            {{ shop.month_total_num | comma }}
          </div>
          <div class="list-item__data col-sales">
            {{ shop.month_total_price | comma }}
          </div>
        </li>
      </ul>
      <div id="total">
        <div class="total-row-1">
          <div class="total-inner">
            <div class="total-col total-col-1"></div>
            <div class="total-col total-col-2 head">総売上数量</div>
            <div class="total-col total-col-3 head">総売上高</div>
          </div>
        </div>
        <div class="total-row-2">
          <div class="total-inner">
            <div class="total-col total-col-1"></div>
            <div class="total-col total-col-2">
              <div class="total-col__row-1 head">当月</div>
            </div>
            <div class="total-col total-col-3">
              <div class="total-col__row-1 head">当月</div>
            </div>
          </div>
        </div>
        <div class="total-row-3">
          <div class="total-inner">
            <div class="total-col total-col-1 head">小 計</div>
            <div class="total-col total-col-2">
              <div class="total-col__row-1 num">
                {{ total.month_total_num | comma }}
              </div>
            </div>
            <div class="total-col total-col-3">
              <div class="total-col__row-1 num">
                {{ total.month_total_price | comma }}
              </div>
            </div>
          </div>
        </div>
        <div class="total-row-4">
          <div class="total-inner">
            <div class="total-col total-col-1 head">合 計</div>
            <div class="total-col total-col-2">
              <div class="total-col__row-1 num">
                {{ total.month_total_num | comma }}
              </div>
            </div>
            <div class="total-col total-col-3">
              <div class="total-col__row-1 num">
                {{ total.month_total_price | comma }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="overlay" :class="{ visible: print }">
      <div
        class="print-paper landscape"
        v-for="paper in this.printNum"
        :key="paper"
      >
        <div class="print-inner">
          <div id="print-meta">
            <p class="print-ttl">商品別売上月報</p>
            <div class="print-sig">
              <div class="print-sig__item"></div>
              <div class="print-sig__item"></div>
              <div class="print-sig__item"></div>
            </div>
            <p class="print-period">{{ date | dateFormatYM }}</p>
            <div class="print-time">
              {{ today }} 現在
              <br />
              {{ paper }}/{{ printNum }}頁
            </div>
          </div>
          <ul class="list">
            <li class="list-head">
              <div class="list-head__item col-name">店舗名</div>
              <div class="list-head__item col-quantity">総売上数量</div>
              <div class="list-head__item col-sales">総売上高</div>
            </li>
            <li class="list-head">
              <div class="list-head__item col-name">店舗名</div>
              <div class="list-head__item col-quantity">総売上数量</div>
              <div class="list-head__item col-sales">総売上高</div>
            </li>
            <li
              class="list-item"
              v-for="shop in list.slice(
                paper * dataPerPage - dataPerPage,
                paper * dataPerPage
              )"
              :key="shop.store_id"
            >
              <div class="list-item__data col-name">{{ shop.name }}</div>
              <div class="list-item__data col-quantity">
                {{ shop.month_total_num | comma }}
              </div>
              <div class="list-item__data col-sales">
                {{ shop.month_total_price | comma }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div id="fixedMenu">
        <div v-on:click="(print = !print), removeSetPrint()">
          <btnS btnTxt="戻る" color="back" />
        </div>
        <div onclick="print()">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#meta {
  width: 100%;
  height: 75px;
  box-sizing: border-box;
  border-bottom: solid 1px variables.$bg-gray-2;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.meta-date {
  @extend .f_pageTtl;
  margin-right: 60px;
}

.meta-month {
  display: flex;
  align-items: center;
  @extend .f_body;
  .buttonXS {
    margin-left: 10px;
  }
}

.meta-year__input,
.meta-month__input {
  box-sizing: border-box;
  border: solid 1px variables.$bg-gray-3;
  border-radius: 3px;
  background: #fff;
  height: 28px;
  padding: 0 12px;
  text-align: right;
  margin-right: 3px;
}

.meta-year__input {
  width: 64px;
}

.meta-month__input {
  width: 46px;
  margin-left: 10px;
}

.meta-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  .buttonS {
    margin: 0 30px;
  }
}

.meta-btn__prev,
.meta-btn__next {
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  cursor: pointer;
}

.meta-btn__prev {
  &:before {
    content: "";
    width: 5px;
    height: 9px;
    background: {
      image: url(../../../../assets/icon/arrow-left-1.svg);
      size: contain;
    }
    display: inline-block;
    margin-right: 15px;
  }
}

.meta-btn__next {
  &:after {
    content: "";
    width: 5px;
    height: 9px;
    background: {
      image: url(../../../../assets/icon/arrow-right-1.svg);
      size: contain;
    }
    display: inline-block;
    margin-left: 15px;
  }
}

.print-btn {
  margin-left: auto;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 160px;
}

.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  width: 1252px;
  margin: 0 auto;
  border-left: solid 1px variables.$bg-gray-2;
}

.list-head {
  display: flex;
  height: 60px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  box-sizing: border-box;
  &:nth-of-type(even) {
    border-left: solid 1px variables.$bg-gray-2;
  }
  &:nth-of-type(odd) {
    margin-right: 30px;
  }
}

.list-subhead {
  display: flex;
  height: 25px;
  font-size: 1.2rem;
  letter-spacing: 0em;
  box-sizing: border-box;
  border-bottom: solid 1px variables.$bg-gray-2;
  &:nth-of-type(even) {
    border-left: solid 1px variables.$bg-gray-2;
  }
  &:nth-of-type(odd) {
    margin-right: 30px;
  }
  .list-item__data {
    justify-content: center;
  }
}

.list-head__item {
  height: 100%;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-head__item-label {
  width: 90px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border {
  background-color: #ededed;
  background-image: repeating-linear-gradient(
    45deg,
    #fff,
    #fff 2px,
    transparent 0,
    transparent 4px
  );
}

.list-item {
  display: flex;
  flex-wrap: wrap;
  height: 40px;
  &:nth-of-type(odd) {
    margin-right: 30px;
  }
  &:nth-of-type(even) {
    box-sizing: border-box;
    border-left: solid 1px variables.$bg-gray-2;
  }
  &:nth-of-type(4n),
  &:nth-of-type(4n - 1) {
    background: variables.$bg-gray-1;
  }
  &.total {
    &:nth-of-type(even) {
      background: none;
    }
  }
}

.list-item__data {
  box-sizing: border-box;
  padding: 0 15px;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  align-items: center;
}

.col-name {
  width: 250px;
}

.col-quantity,
.col-sales {
  width: 180px;
}

.col-half {
  width: 90px;
  justify-content: flex-end;
}

#total {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(59, 64, 67, 0.9);
  width: 100%;
  height: 130px;
  color: #fff;
  .num {
    justify-content: flex-end;
    font-size: 1.4rem;
  }
  .head {
    justify-content: center;
    font-size: 1.2rem;
  }
}

.total-inner {
  width: 480px;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.total-col {
  height: 100%;
  display: flex;
  align-items: center;
}

.total-col-1 {
  width: 120px;
}

.total-col-2 {
  width: 180px;
}

.total-col-3 {
  width: 180px;
}

.total-col__row-1 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.total-row-1,
.total-row-2 {
  height: 25px;
}

.total-row-2 {
  box-sizing: border-box;
  border-bottom: solid 1px variables.$txt-default;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 1px;
    background: variables.$txt-default;
    position: absolute;
    left: 0;
    bottom: -3px;
  }
}

.total-row-3,
.total-row-4 {
  height: 40px;
}

.total-row-3 {
  box-sizing: border-box;
  border-bottom: solid 1px variables.$txt-default;
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  box-sizing: border-box;
  padding: 30px 0 120px;
  overflow: scroll;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

#print-meta {
  margin-bottom: 20px;
}

.print-time {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-align: right;
}

.print-sig {
  display: flex;
  position: absolute;
  top: 30px;
  right: 160px;
  border-left: solid 1px variables.$txt-default;
}

.print-sig__item {
  box-sizing: border-box;
  width: 55px;
  height: 50px;
  border-right: solid 1px variables.$txt-default;
  border-top: solid 1px variables.$txt-default;
  border-bottom: solid 1px variables.$txt-default;
  position: relative;
  &:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    left: 11px;
    top: 0;
    background: variables.$txt-default;
  }
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 30px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}
</style>
<style lang="scss">
.meta-calendar__input {
  color: rgba(255, 255, 255, 0);
}
</style>
<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnXS from "@/components/btn/btnXS";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    btnXS,
    btnS,
    btnL
  },
  data: function() {
    return {
      backBtn: true,
      print: false,
      today: "",
      date: "",
      year: "",
      month: "",
      list: [],
      total: {},
      rowNum: 1, //店舗数 / カラム数
      dataPerRow: 7, //1ページあたりのカラム数
      dataPerPage: 36, //1ページあたりのデータ数
      pagePerItems: 1, //商品1周分で何枚必要か
      printNum: 1 //印刷枚数 = 商品数 / dataPerPage * rowNum
    };
  },
  mixins: [Global],
  async created() {
    this.date = new Date();
    const y = this.date.getFullYear();
    const m = this.date.getMonth() + 1;
    const d = this.date.getDate();
    this.year = y;
    this.month = m;
    this.today = y + "年" + m + "月" + d + "日";
    this._read();
  },
  methods: {
    setYM() {
      this.date = new Date(this.year, parseInt(this.month, 10) - 1, 1);
      if (Number.isNaN(this.date.getDate())) {
        this.date = new Date();
      }
      const y = this.date.getFullYear();
      const m = this.date.getMonth() + 1;
      this.year = y;
      this.month = m;
      this._read();
    },
    setToday() {
      this.date = new Date();
      this._read();
    },
    beforeDate() {
      this.date.setMonth(this.date.getMonth() - 1);
      this._read();
    },
    nextDate() {
      this.date.setMonth(this.date.getMonth() + 1);
      this._read();
    },
    changePicker(date) {
      this.date = date;
      this._read();
    },
    async _read() {
      const y = this.date.getFullYear();
      const m = this.date.getMonth() + 1;
      const d = this.date.getDate();

      const param = {
        date: y + ("0" + m).slice(-2) + ("0" + d).slice(-2)
      };
      const api = new ApiClient();
      const result = await api.post(`/iseya/sale/shop`, param);
      this.list = result.list;
      this.total = result.total;

      this.rowNum = 7;
      this.printNum = Math.ceil(this.list.length / this.dataPerPage);
      this.pagePerItems = parseInt(this.list.length / this.dataPerPage);
    },
    setPrint() {
      let html = document.querySelector("html");
      html.classList.add("print-landscape");
      document.body.classList.add("print-landscape");
    },
    removeSetPrint() {
      let html = document.querySelector("html");
      html.classList.remove("print-landscape");
      document.body.classList.remove("print-landscape");
    }
  }
};
</script>
